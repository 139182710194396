import { IGetFoodItemListHook } from 'application/modules/bookingWizard/useCases/hooks/useCaseGetFoodItemList';
import mboAPI from 'infrastructure/redux/adapters/mboAPI/mboApi';
import { selectLocation } from 'infrastructure/redux/slices/bookingWizard.selector';
import { useAppSelector } from 'infrastructure/redux/store/hooks';

const useGetFoodItemList: IGetFoodItemListHook = () => {
  const locationId = useAppSelector(selectLocation);

  const [getFoodItemList, { data, isLoading, error, isFetching }] =
    mboAPI.useLazyGetFoodItemListQuery();

  const {
    data: foodCategoryList,
    error: foodCategoryListError,
    isLoading: foodCategoryListLoading,
    isFetching: foodCategoryListFetching,
    refetch: fetchFoodCategoryList,
  } = mboAPI.useGetFoodCategoryListQuery();

  const handleFetchFoodItemList = async (
    categoryId: string,
    pagination?: {
      limit?: number;
      cursor?: string | null;
    },
  ) => {
    if (locationId) {
      await getFoodItemList({
        locationId: locationId,
        categoryIds: categoryId,
        limit: pagination?.limit,
        cursor: pagination?.cursor,
      });
    }
  };

  return {
    inProgress: isLoading || isFetching,
    error: error,
    result: data,
    fetchFoodItemList: handleFetchFoodItemList,
    foodCategoryListInProgress: foodCategoryListLoading || foodCategoryListFetching,
    foodCategoryListError: foodCategoryListError,
    fetchFoodCategoryList,
    foodCategoryList,
  };
};

export default useGetFoodItemList;
